import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  const email = "fallswebmaster@gmail.com";

  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="footer-text">
          Contact: <a href={`mailto:${email}`}>{email}</a>
        </p>
        <br />
        <p className="footer-text">© 2023 WF Web Master</p>
      </div>
    </footer>
  );
};

export default Footer;
