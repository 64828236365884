import React from "react";
import "../styles/About.css";

const About = () => {
  return (
    <section id="about">
      <div className="container">
        <h2>About WF Web Master</h2>
        <p>
          Welcome to WF Web Master, the leading web design company serving
          Wichita Falls, TX, and the surrounding communities. We specialize in
          creating stunning, user-friendly websites that help businesses
          establish a strong online presence. Our experienced team of designers
          and developers are dedicated to bringing your vision to life.
        </p>
        <p>
          At WF Web Master, we combine aesthetics with functionality, ensuring
          that your website looks amazing and performs flawlessly across all
          devices. We create responsive designs that provide a seamless user
          experience. We offer ongoing maintenance and support services to keep
          your website running smoothly. Contact us today to discuss your web
          design needs and let us help you turn your ideas into reality.
        </p>
      </div>
    </section>
  );
};

export default About;
