import React from "react";
import "../styles/Portfolio.css";

const websiteData = [
  { url: "https://www.wf-web-master.com", title: "Website 1" },
  { url: "https://www.wf-web-master.com", title: "Website 2" },
  { url: "https://www.wf-web-master.com", title: "Website 3" },
  { url: "https://www.wf-web-master.com", title: "Website 4" },
];

const Portfolio = () => {
  return (
    <div className="portfolio">
      <h2 className="portfolio-title">Portfolio</h2>
      <div className="portfolio-container">
        {websiteData.map((website, index) => (
          <iframe
            key={index}
            src={website.url}
            title={website.title}
            className="portfolio-iframe"
          />
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
