import React from "react";
import "../styles/Services.css";

const Services = () => {
  const serviceData = [
    {
      title: "Web Design",
      description:
        "Our web design service encompasses all aspects of your online presence. We create visually appealing websites, help secure the perfect domain name, optimize for search engines, and ensure a seamless user experience across devices. By working closely with you, we will deliver a customized website that effectively represents your brand.",
    },
    {
      title: "Maintenance and Updates",
      description:
        "Ensure your website's optimal performance and security with our reliable maintenance and update services. We offer regular updates, security checks, bug fixes, and content management, freeing you to focus on your core business. Let us handle the technical aspects while you enjoy peace of mind.",
    },
    {
      title: "Custom Logo Creation",
      description:
        "Stand out with a unique and memorable logo tailored to your brand. Our custom logo creation service combines creativity and expertise to design a logo that captures your brand identity. We take into account your preferences, target audience, and industry to create a logo that represents your business effectively.",
    },
    // {
    //   title: "E-commerce Solutions",
    //   description:
    //     "Our e-commerce solutions are tailored to meet your specific business needs. We specialize in setting up and customizing online stores, integrating secure payment gateways, managing product catalogs, and implementing shopping cart functionality. With our expertise, your e-commerce website will be optimized for success.",
    // },
  ];

  return (
    <section id="services">
      <h2 className="services-title">Services</h2>
      {serviceData.map((service, index) => (
        <div className="service" key={index}>
          <h3>{service.title}</h3>
          <p>{service.description}</p>
        </div>
      ))}
    </section>
  );
};

export default Services;
