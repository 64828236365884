import React from "react";

import "../styles/Pricing.css";

const pricingPackages = [
  {
    title: "Business Site",
    packageName: "Business Site",
    price: "$500 initial (Discounted Price: $300) $50/month for hosting",
    description:
      "This package includes the creation of a simple business information site. It encompasses the design and development of a responsive website that showcases your company's information, services, and contact details.",
  },
  {
    title: "Content Management and Updates",
    packageName: "Content Management and Updates",
    price: "Starting at $100/month",
    description:
      "With this package, you receive ongoing content management and updates for your website. This includes regular maintenance, content modifications, and technical support to ensure your website remains up-to-date and optimized.",
  },
  {
    title: "Logo Design",
    packageName: "Logo Design",
    price: "$200 (Discounted Price: $150)",
    description:
      "Our professional design team will create a unique and eye-catching logo for your business. The starting price includes initial concepts and revisions based on your feedback to ensure the final logo represents your brand effectively.",
  },
  // {
  //   title: "E-commerce Site",
  //   packageName: "E-commerce Site",
  //   price: "Varies (Contact us for more information)",
  //   description:
  //     "If you require an e-commerce site with advanced functionality such as online shopping, product listings, and secure payment gateways, we offer customized solutions tailored to your specific needs. Please contact us with your requirements, and we'll provide you with a personalized quote.",
  // },
];

const Pricing = () => {
  return (
    <div className="pricing-section">
      <h2>Pricing</h2>
      {pricingPackages.map((pricingPackage) => (
        <div className="pricing-package" key={pricingPackage.title}>
          <h3>{pricingPackage.title}</h3>
          <p>Package: {pricingPackage.packageName}</p>
          <p>Price: {pricingPackage.price}</p>
          <p>{pricingPackage.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Pricing;
