import React, { useState } from "react";
import axios from "axios";
import { API_URL } from "../config";

import "../styles/Form.css";

const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state variable

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true); // Set isSubmitting to true when submitting the form

    try {
      const response = await axios.post(`${API_URL}/webmaster/submit-request`, {
        name,
        email,
        message,
        phone,
      });

      if (response.status === 200) {
        setFormSubmitted(true);
      } else {
        console.log("Error submitting form");
      }
    } catch (error) {
      console.log("Error submitting form", error);
    }

    setIsSubmitting(false); // Set isSubmitting back to false after receiving the response

    // Reset form fields
    setName("");
    setEmail("");
    setMessage("");
    setPhone("");
  };

  return (
    <div className="form">
      <div className="form-container">
        <h2>Contact</h2>
        {formSubmitted ? (
          <div className="success-message">
            <p>Form submitted successfully!</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="request-form">
            <div className="form-field">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={handleNameChange}
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="phone">Phone:</label>
              <input
                type="tel"
                id="phone"
                value={phone}
                onChange={handlePhoneChange}
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="message">Description:</label>
              <textarea
                id="message"
                value={message}
                onChange={handleMessageChange}
                placeholder="Enter a description of your business and the desired service and we will get back to you as soon as possible!"
                required
              ></textarea>
            </div>
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Sending..." : "Submit"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Form;
