import React from "react";
import Form from "./Form";
import Services from "./Services";
import Banner from "./Banner";
import About from "./About";
import Pricing from "./Pricing";
import Portfolio from "./Portfolio";

const Home = () => {
  return (
    <div>
      <Banner />
      <About />
      <Services />
      <Portfolio />
      <Pricing />
      <Form />
    </div>
  );
};

export default Home;
